import React, { LegacyRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay, { AutoplayType } from "embla-carousel-autoplay";
import classnames from "classnames";

import Caret from "storefront/components/Icons/Navigation/Caret";

import styles from "./CarouselNext.module.scss";

export type CarouselApi = {
  canScrollPrev: () => boolean;
  canScrollNext: () => boolean;
  scrollPrev: () => void;
  scrollNext: () => void;
  selectedScrollSnap: () => number;
  scrollTo: (index: number) => void;
  off: (event: string, callback: () => void) => void;
  on: (event: string, callback: () => void) => void;
  scrollSnapList: () => number[];
};

type CarouselContextProps = {
  carouselRef: LegacyRef<HTMLDivElement>;
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  scrollTo: (slide: number) => void;
  slideCount: number;
  activeSlide: number;
};

type AutoplayPlugin = AutoplayType;

type AutplayPluginOptions = {
  delay?: number;
  stopOnInteraction?: boolean;
};

type Plugin = AutoplayPlugin;

type Plugins = Plugin[];

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

type CarouselSlideProps = {
  children: React.ReactNode;
  className?: string;
};

export function CarouselSlide({ children, className }: CarouselSlideProps) {
  return (
    <div
      role="group"
      aria-roledescription="slide"
      className={classnames(styles.slide, className)}
    >
      {children}
    </div>
  );
}

type CarouselContentProps = {
  children: React.ReactNode;
  className?: string;
};

export function CarouselContent({ children, className }: CarouselContentProps) {
  const { carouselRef } = useCarousel();
  return (
    <div ref={carouselRef} className={styles.wrapper}>
      <div className={classnames(styles.container, className)}>{children}</div>
    </div>
  );
}

type CarouselProps = {
  children: React.ReactNode;
  loop?: boolean;
  setApi?: (api: CarouselApi) => void;
  onChange?: (index: number) => void;
  autoscroll?: AutplayPluginOptions | boolean;
  startIndex?: number;
};

const DEFAULT_DELAY = 7000;

const DEFAULT_STOP_ON_INTERACTION = true;

export const Carousel = React.forwardRef(function Carousel(
  {
    children,
    loop,
    setApi,
    onChange,
    autoscroll = false,
    startIndex = 0,
  }: CarouselProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const pluginConstructorArray: Plugins = [];

  if (autoscroll) {
    if (
      typeof autoscroll === "object" &&
      autoscroll.delay &&
      autoscroll.delay < 3000
    ) {
      throw new Error(
        `The delay for the autoscroll plugin must be at least 3000ms. The current delay is ${autoscroll.delay}ms.`,
      );
    }

    const delay =
      typeof autoscroll === "object" && autoscroll.delay
        ? autoscroll.delay
        : DEFAULT_DELAY;

    const stopOnInteraction =
      typeof autoscroll === "object" &&
      autoscroll.stopOnInteraction !== undefined
        ? autoscroll.stopOnInteraction
        : DEFAULT_STOP_ON_INTERACTION;

    pluginConstructorArray.push(Autoplay({ delay, stopOnInteraction }));
  }

  const [carouselRef, emblaInstance] = useEmblaCarousel(
    {
      loop,
      startIndex,
    },
    pluginConstructorArray,
  );

  const [canScrollPrev, setCanScrollPrev] = React.useState(false);
  const [canScrollNext, setCanScrollNext] = React.useState(false);
  const [activeSlide, setActiveSlide] = React.useState(startIndex);

  const onSelect = React.useCallback(
    (api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
      setActiveSlide(api.selectedScrollSnap());
      onChange?.(api.selectedScrollSnap());
    },
    [onChange],
  );

  React.useEffect(() => {
    if (!emblaInstance || !setApi) {
      return;
    }

    setApi(emblaInstance);
  }, [emblaInstance, setApi]);

  React.useEffect(() => {
    if (!emblaInstance) {
      return;
    }

    onSelect(emblaInstance);
    emblaInstance.on("reInit", onSelect);
    emblaInstance.on("select", onSelect);

    return () => {
      emblaInstance?.off("select", onSelect);
    };
  }, [emblaInstance, onSelect]);

  const maybeResetAutoScroll = React.useCallback(() => {
    const autoplay = emblaInstance?.plugins()?.autoplay;
    if (!autoplay) return;
    autoplay.reset();
  }, [emblaInstance]);

  const scrollPrev = React.useCallback(() => {
    emblaInstance?.scrollPrev();
    maybeResetAutoScroll();
  }, [emblaInstance, maybeResetAutoScroll]);

  const scrollNext = React.useCallback(() => {
    emblaInstance?.scrollNext();
    maybeResetAutoScroll();
  }, [emblaInstance, maybeResetAutoScroll]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        scrollPrev();
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        scrollNext();
      }
    },
    [scrollPrev, scrollNext],
  );

  const scrollTo = React.useCallback(
    (slide: number) => {
      emblaInstance?.scrollTo(slide);
    },
    [emblaInstance],
  );

  const slideCount = React.useMemo(() => {
    return emblaInstance?.scrollSnapList().length || 0;
  }, [emblaInstance]);

  return (
    <CarouselContext.Provider
      value={{
        carouselRef,
        scrollPrev,
        scrollNext,
        canScrollPrev,
        canScrollNext,
        scrollTo,
        slideCount,
        activeSlide,
      }}
    >
      <div
        className={styles.carousel}
        role="region"
        aria-roledescription="carousel"
        onKeyDownCapture={handleKeyDown}
        ref={ref}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  );
});

type CarouselNavProps = {
  className?: string;
};

export function CarouselNavNext({ className }: CarouselNavProps) {
  const { scrollNext, canScrollNext } = useCarousel();
  return (
    <button
      className={classnames(
        styles.button,
        styles.next,
        styles.navButton,
        className,
      )}
      type="button"
      onClick={scrollNext}
      disabled={!canScrollNext}
      aria-label="Next"
      data-testid="next"
    >
      <Caret className={styles.caret} direction="right" />
    </button>
  );
}

export function CarouselNavPrev({ className }: CarouselNavProps) {
  const { scrollPrev, canScrollPrev } = useCarousel();
  return (
    <button
      className={classnames(
        styles.button,
        styles.prev,
        styles.navButton,
        className,
      )}
      type="button"
      onClick={scrollPrev}
      disabled={!canScrollPrev}
      aria-label="Previous"
      data-testid="prev"
    >
      <Caret className={styles.caret} direction="left" />
    </button>
  );
}

type CarouselNavDotsProps = {
  className?: string;
};

export function CarouselNavDots({ className }: CarouselNavDotsProps) {
  const { activeSlide, slideCount, scrollTo } = useCarousel();

  return (
    <div
      className={classnames(styles.dots, className)}
      role="group"
      aria-label="Slide controls"
      data-testid="dots"
    >
      {Array.from({ length: slideCount }).map((_, index) => (
        <button
          type="button"
          key={index}
          onClick={() => scrollTo(index)}
          aria-label={`Scroll to slide ${index + 1}`}
          aria-disabled={index === activeSlide ? "true" : undefined}
          data-active={index === activeSlide}
        />
      ))}
    </div>
  );
}
